import axios from 'axios'


 const api = axios.create({
    withCredentials: true,
    baseURL : "https://mapadmin.zedtrack.com/",
    //baseURL:'http://127.0.0.1:8000/',
    //baseURL:'http://59.152.103.142:8010/',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
});
let refresh = false;

api.interceptors.request.use((config)=>{
    if(config.url==='/auth/login/'||config.url==='/auth/logout/'||config.url==='/auth/registration/'){
        return config
    }else{ 
    console.log(config)
     const token = localStorage.getItem('jwt');
    const b_token='Bearer ' +token
    //     // console.log(b_token,22)
    //     api.defaults.headers.common['Authorization'] = b_token;
    config.headers.common['Authorization'] = b_token;
    return config
    }
})



// api.interceptors.response.use(resp => resp, async error => {
//     console.log(error.response.status)
//     if (error.response.status === 401 && !refresh) {
//         refresh = true;
//         console.log("Entered interceptors",17)
//         const token = localStorage.getItem('jwt');
//         const r_token = localStorage.getItem('r_token');
//         // console.log(token,20);
//         const b_token='Bearer ' +token
//         // console.log(b_token,22)
//         api.defaults.headers.common['Authorization'] = b_token;
//          console.log(api.defaults.headers.common,23)
//        const {status, data} = await api.post('auth/token/refresh/', {'r_token': r_token}, {
//            withCredentials: true
//        });
//        console.log("Entered interceptors", status)
//        if (status === 200) {
//            api.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;

//            return api(error.config);
//        }
//    }
//    refresh = false;
//    return error;
// });


export default api;