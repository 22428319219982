import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Login from '../views/login.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/register.vue')
  // },
  {
    path: '/map_form',
    name: 'map_form',
    component: () => import(/* webpackChunkName: "about" */ '../views/MapForm.vue')
  },
  {
    path: '/data',
    name: 'data',
    component: () => import(/* webpackChunkName: "about" */ '../views/Data.vue')
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || ''),
  routes
})

router.beforeEach((to, _, next) => {
  if (to.name != 'login') {
    let isAuthenticated = false;
    if (localStorage.getItem('email') != null) {
      isAuthenticated = true;
    } else {
      isAuthenticated = false;
    }

    if (isAuthenticated) {
      next();
    } else {
      next('/');
    }
  }
  next();
});

export default router
