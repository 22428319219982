<template>
  <div>
    <div>
      <Sidebar />
    </div>
    <div>
      <div class="w-full h-10 bg-app-navbar mb-3">
        <p class=" text-xl pt-2 font-bold text-white"> Home </p>
      </div>
      <div class="flex justify-center">
        <div class="w-30">
          <vue-datepicker @closed="handleDateTimeRange" range :partial-range="false" :enableTimePicker="false"
            placeholder="Select Date Time" ref="datepicker" v-model="date" />
        </div>
      </div>
      <div class="flex justify-center mt-5">
        <h1 class="text-lg fw-bold">Count: {{ count }}</h1>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from '@/components/sidebar/Sidebar'
import { sidebarWidth } from '@/components/sidebar/state'
import api from "../../boot/axios.js";

export default ({
  components: { Sidebar },

  setup() {
    return { sidebarWidth }
  },

  data() {
    return {
      date: null,
      startingDate: null,
      endingDate: null,
      count: 0,
    }
  },

  methods: {
    handleDateTimeRange() {
      if (!this.date) {
        return;
      }
      try {
        const startingDate = this.date[0].toISOString();
        const endingDate = this.date[1].toISOString();
        this.startingDate = startingDate.split('T')[0];
        this.endingDate = endingDate.split('T')[0];
        this.getCount();
      } catch (_) {
        return;
      }
    },

    async getCount() {
      const userid = localStorage.getItem('id');
      const response = await api.get(`api/user-location-count/?user=${userid}&created_at_after=${this.startingDate}&created_at_before=${this.endingDate}`);
      this.count = response.data.total_location || 0;
    },

  }
})
</script>